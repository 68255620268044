import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper jzih1dpqqrg-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo jzjgnya1gmn-editor_css',
    children: 'https://s3.bmp.ovh/imgs/2022/09/02/b4413d988ce0da3b.png',
  },
  Menu: {
    className: 'header3-menu l7k7h5bcvf-editor_css',
    children: [
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#',
          children: [{ children: <p>订订群</p>, name: 'text' }],
        },
        subItem: [
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item jzj8295azrs-editor_css',
              children: [
                {
                  name: 'image0',
                  className: 'item-image jzj81c9wabh-editor_css',
                  children:
                    'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*4_S6ToPfj-QAAAAAAAAAAABkARQnAQ',
                },
              ],
            },
            name: 'sub~jzj8hceysgj',
          },
        ],
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page l7jx4q9b92-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>奚仲智能交通数据服务平台</p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'banner5-title l7k7hyorxb-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>构建ETC-X车联网的开放生态</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              以既有ETC体系技术和政策背景为基础，推动ETC进入ETC-X（V/I/N）时代，实现感知、决策、交互等环节的联动，为构建数字交通提供海量全面的数据支撑，为V2X车路协同智能互联的演进提供更平滑过渡，为推动国家智能汽车战略、交通强国战略和网络强国战略贡献作用价值。
            </p>
          </span>
        ),
      },
    ],
  },
  image: {
    className: 'banner5-image l7k7ina3kv-editor_css',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper l7k8x10j2df-editor_css',
  },
  OverPack: {
    className: 'home-page content1 l7ka74e9j9k-editor_css',
    playScale: 0.3,
  },
  imgWrapper: { className: 'content1-img', md: 14, xs: 24 },
  img: {
    children: 'https://s3.bmp.ovh/imgs/2022/09/02/8a31e689845b77ac.png',
    className: 'l7k8t4a867e-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 10, xs: 24 },
  title: {
    className: 'content1-title l7kaekan3mo-editor_css',
    children: (
      <span>
        <span>
          <p>奚仲云 PaaS</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          提供交通大数据应用的系统集成、敏捷开发、高效运营等一站式应用解决方案，能有效降低ETC-X应用企业研发成本，提升开发效率，协助企业快速搭建稳健开放的ETC服务应用。
        </p>
      </span>
    ),
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6 l7k977bogpi-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 12 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>奚仲云集市</p>
            </span>
          </span>
        ),
        className: 'title-h1 l7kaer7mw37-editor_css',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <p>
                整合应用集市，轻量化、快速构建海量轻量化的ETC应用，<span>
                  为各ETC-X的行业用户提供最高效的整合工具，让行业用户通过奚仲平台最快、最高效的开发各类ETC-X应用服务工具，快速的部署、快速嵌入、快速应用。
                </span>
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://s3.bmp.ovh/imgs/2022/09/02/64ff65f60763df1f.jpg',
    className: 'content6-img l7k8wgbqe6-editor_css',
    xs: 24,
    md: 12,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>重塑开发</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                基于奚仲平台的集成兼容能力，极大的减轻ETC-X场景运行方的连接整合压力和建设工作量，能够快速实现聚合、开发和部署，有效降低前期开发建设成本。
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>AI最优解设计</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                基于奚仲平台上海量的业务流程、应用策略，能够帮助ETC-X场景开发者快速的寻找到最佳的场景开发和应用方案，最快速的完成设计。
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>云端部署，快速迭代</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                基于奚仲平台来开发及搭建应用软件，能够完全部署在云端，方便了海量的线下场景的实时更新和迭代。
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Content90DataSource = {
  wrapper: { className: 'home-page-wrapper content9-wrapper' },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>奚仲盒子</p>
          </span>
        ),
        className: 'title-h1 l7kamhgokzl-editor_css',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img l7kasz3m4y-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: {
            className: 'block-name l7kaqo3waf-editor_css',
            children: '姓名',
          },
          post: {
            className: 'block-post l7kaqwjwikk-editor_css',
            children: '公司 职位',
          },
          time: {
            className: 'block-time l7kclt2wup-editor_css',
            children: '09:00 - 10:00',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>奚仲盒子接入本地的场景系统（停车场，加油等）</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  奚仲盒子通过本地路由器，与本地的相关硬件进行交互，并实现握手控制，主要硬件有如：路侧单元（RSU），摄像头、停车杆，加油，充电桩控制器等。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img l7kau94ihwj-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: {
            className: 'block-name l7kaqycjo9-editor_css',
            children: '姓名',
          },
          post: {
            className: 'block-post l7kar0qx4h-editor_css',
            children: '公司 职位',
          },
          time: {
            className: 'block-time l7kaouuvxsl-editor_css',
            children: '09:00 - 10:00',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>奚仲盒子完成本地的硬件适配，并通过互联网链接奚仲云PAAS</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <p>- 奚仲盒子通过互联网与奚仲云进行对接</p>
                  <p>
                    -
                    奚仲云端根据业务流程需求，配置相应的处理流程，并且与本地的奚仲盒子进行联调
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img l7kavm8eqzp-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          name: {
            className: 'block-name l7kar2uo2wf-editor_css',
            children: '姓名',
          },
          post: {
            className: 'block-post l7kar4hzb2-editor_css',
            children: '公司 职位',
          },
          time: {
            className: 'block-time l7kapffk9f-editor_css',
            children: '09:00 - 10:00',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>
                  日常业务中，奚仲盒子负责本地硬件管理以及云端业务结果的处理
                </p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  -
                  日常业务过程中，奚仲盒子如果接收到RSU单元的对于ETC的识别以后，根据配置的业务场景，上传相应的数据到奚仲云PAAS
                </p>
                <p>
                  -
                  奚仲云PAAS根据配置的业务流程，处理相应的业务，并回传结果给奚仲盒子
                </p>
                <p>
                  -
                  奚仲盒子根据回传的结果，控制停车杆抬杆，或者加油，充电桩完成加油，充电动作。
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1 l7k8kt5kte-editor_css',
        children: (
          <span>
            <p>奚仲大数据</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: (
          <span>
            <p>承接硬件、软件、金融等各方服务商数据</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button l7k8km1pjfl-editor_css',
        children: { href: '#', children: '立即体验' },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child l7kbvmg2klc-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image l7kbg54zw6o-editor_css',
                    children:
                      'https://s3.bmp.ovh/imgs/2022/09/02/b07c5af79db5a19a.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>数据采集</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l7k9hb10efj-editor_css',
                    children: (
                      <span>
                        <p>车载OBU</p>
                        <p>场景RSU</p>
                        <p>车牌识别</p>
                        <p>视频算法</p>
                        <p>激光轨迹</p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.bmp.ovh/imgs/2022/09/02/38a33ed4cae33698.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>数据处理</p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l7k9hiilfwe-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>IoT智能物联网运行数据管理平台</p>
                          <p>
                            <br />
                          </p>
                          <p>
                            <span>交易清分结算数据管理服务平台</span>
                          </p>
                          <p>
                            <span>
                              <br />
                            </span>
                          </p>
                          <p>
                            <span>用户发行售后应用数据服务平台</span>
                          </p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.bmp.ovh/imgs/2022/09/02/80b1794528c5983f.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>数据应用</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l7k9ic2cs7d-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>路内/路外停车应用</p>
                          <p>
                            <span>
                              <br />
                            </span>
                          </p>
                          <p>
                            <span>加油/充电应用</span>
                          </p>
                          <p>
                            <span>
                              <br />
                            </span>
                          </p>
                          <p>
                            <span>城市车辆静/动态交通数据应用</span>
                          </p>
                          <p>
                            <span>
                              <br />
                            </span>
                          </p>
                          <p>
                            <span>智能AI策略算法应用</span>
                          </p>
                          <p>
                            <span>
                              <br />
                            </span>
                          </p>
                          <p>
                            <span>产业供应链金融应用</span>
                          </p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.bmp.ovh/imgs/2022/09/02/78e8a93253f9f0f4.png',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>数据商业</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content l7k9j704nie-editor_css',
                    children: (
                      <span>
                        <p>交易结算服务</p>
                        <p>
                          <br />
                        </p>
                        <p>数据应用服务</p>
                        <p>
                          <br />
                        </p>
                        <p>智能策略服务</p>
                        <p>
                          <br />
                        </p>
                        <p>广告 / 营销服务</p>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper l7ka3ak2bep-editor_css',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text l7ka2wooqn-editor_css',
          children: (
            <span>
              <p>阶段成果</p>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper l7k9yi24uy-editor_css',
            name: 'child0',
            number: {
              className: 'feature6-number l7ka4g3ze6o-editor_css',
              unit: {
                className: 'feature6-unit l7k9wwz8la-editor_css',
                children: (
                  <span>
                    <p>省</p>
                  </span>
                ),
              },
              toText: true,
              children: '15',
            },
            children: {
              className: 'feature6-text l7k9yhg0zum-editor_css',
              children: (
                <span>
                  <span>
                    <p>连接15省路网ETC数据传输及交易扣费</p>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number l7ka4p5ggl-editor_css',
              unit: {
                className: 'feature6-unit l7k9xialitf-editor_css',
                children: (
                  <span>
                    <p>+</p>
                  </span>
                ),
              },
              toText: true,
              children: '7',
            },
            children: {
              className: 'feature6-text l7k9yz9hc9e-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>支持7大ETC主要设备制造商硬件</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number l7ka4yzo1m8-editor_css',
              unit: {
                className: 'feature6-unit l7k9xv7e2vh-editor_css',
                children: (
                  <span>
                    <p>%</p>
                  </span>
                ),
              },
              toText: true,
              children: '60',
            },
            children: {
              className: 'feature6-text l7k9z2qyptm-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>支持60%以上车牌识别设备硬件</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 6,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child~l7k9y59jpds',
            number: {
              className: 'feature6-number l7ka5axwzqi-editor_css',
              unit: {
                className: 'feature6-unit l7k9xv7e2vh-editor_css',
                children: (
                  <span>
                    <span>
                      <p>+</p>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '60',
            },
            children: {
              className: 'feature6-text l7k9z74lz6l-editor_css',
              children: (
                <span>
                  <span>
                    <p>支持60+场景运营商系统</p>
                  </span>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper l7ll95uh4q-editor_css',
  },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          ©2022&nbsp;<a href="https://xizhong.cloud/">XiZhong</a>&nbsp;All
          Rights Reserved
        </span>
      </span>
    ),
  },
};
